/* Code section title */
.code-section-title {
  font-size: 16px;
  font-weight: 600;
}

.inspect-code-ant-drawer-header .ant-drawer-header {
  padding: 16px 24px 8px 16px !important; 
}
.inspect-code-drawer-title {
  display: flex;
  font-weight: 600;
  font-size: 20px;
  align-items: center;
  flex-grow: 1; 
  font-family: 'Inter',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* Ensure it takes up the space needed */
}

.link-color {
  color: #3B41EC;
  font-family: 'Inter',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.drawer-subtitle {
  font-family: 'Inter',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.custom-tab-bar .ant-tabs-tab {
  color: #8c97a5; 
}


/* Code content styling */
.code-content {
  background-color: #000;
  color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px;
  margin: 0px;
  font-family: 'Consolas', 'Monaco', 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 14px;
  overflow: auto;
}

/* Code snippet styling */
.code-snippet {
  padding: 16px 16px 16px 16px;
  border-top: none;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
