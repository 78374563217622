.all-livechat-page-area {
  width: 100%;
  position: relative;
}

.livechat-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.map-interaction-wrapper {
  margin: 0 auto;
}
.livechat-customization-btn-container {
  position: absolute; /* Position the button absolutely */
  top: 20px; /* Adjust top position as needed */
  right: 24px; /* Adjust right position as needed */
  z-index: 1;
  background-color: #fff;
}
.layer-btn-container {
  position: absolute; /* Position the button absolutely */
  top: 20px; /* Adjust top position as needed */
  left: 36px;
  z-index: 1;
  background-color: #fff;
}
.layer-btn-container-2 {
  position: absolute; /* Position the button absolutely */
  top: 20px; /* Adjust top position as needed */
  left: 180px;
  z-index: 1;
  background-color: #fff;
}
.custom-btn-row {
  display: flex;
  justify-content: end;
  margin-right: 24px;
}
.button-icon {
  width: 24px;
  height: 24px;
  color: var(--Icon-Gray2, #424242);
  margin-right: 8px;
}
.custom-btn {
  margin-top: 24px;
  max-width: 10.5rem;
  box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.2),
    0px 0px 2px 0px rgba(40, 41, 61, 0.1);
}
/* Drawer header layout */
.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Drawer title with icon */
.drawer-title {
  display: flex;
  font-weight: 600;
  font-size: 20px;
  align-items: center;
  flex-grow: 1;
  /* Ensure it takes up the space needed */
}

.drawer-icon {
  margin-right: 8px; /* Space between icon and text */
}

/* Close button styling */
.drawer-close-button {
  background: none;
  border: none;
  cursor: pointer;
  color: black;
  padding: 0;
  max-width: 1.75rem;
  max-height: 1.75rem;
  font-size: 1.5rem; /* Adjust the size as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto; /* Push the button to the right */
}

.drawer-close-button:hover {
  background-color: #de4e50; /* danger red */
  color: #ffffff; /* white */
}
.drawer-close-button:hover .fa,
.drawer-close-button:hover svg {
  color: #ffffff;
}

.code-section-title {
  font-size: 16px;
  font-weight: 600;
}

.code-content {
  background-color: #000;
  color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px;
  margin: 0px;
  font-family: "Consolas", "Monaco", "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 14px;
  overflow: auto;
}

/* Specific syntax coloring */
.code-content.json {
  color: #9cdcfe; /* Default color for JSON text */
}

.code-content.json .key {
  color: #4ec9b0; /* Color for keys */
}

.code-content.json .string {
  color: #ce9178; /* Color for strings */
}

.code-content.json .number {
  color: #b5cea8; /* Color for numbers */
}

.custom-tab-bar .ant-tabs-tab {
  color: #8c97a5;
}

.code-snippet {
  padding: 16px 16px 16px 16px;
  border-top: none;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  border-bottom-left-radius: 8px; /* Adjust the radius as needed */
  border-bottom-right-radius: 8px; /* Adjust the radius as needed */
}

li {
  margin-bottom: 8px;
}

/* Styles for the active tab */
.custom-tab-bar .ant-tabs-tab.ant-tabs-tab-active,
.custom-tab-bar .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black; /* Custom color for active tab */
}

/* Override hover styles for the active tab */
.custom-tab-bar .ant-tabs-tab.ant-tabs-tab-active:hover,
.custom-tab-bar .ant-tabs-tab.ant-tabs-tab-active:hover .ant-tabs-tab-btn {
  color: black; /* or the color you want for active tab */
}
.custom-tab-bar .ant-tabs-tab.ant-tabs-tab:hover,
.custom-tab-bar .ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: #8c97a5; /* Same as the non-active tab text color */
}

.custom-tab-bar .ant-tabs-nav {
  margin-bottom: 0;
}

.custom-tab-bar .ant-tabs-card .ant-tabs-nav {
  margin-bottom: 0;
}

.custom-tab-bar .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 0px;
}

.custom-input-group {
  border-radius: 8px;
}

.livechat-iframe {
  border: none;
  box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.2),
    0px 0px 2px 0px rgba(40, 41, 61, 0.1);
  border-radius: 20px;
  margin: 20px;
  background-color: #fff;
  padding-bottom: 5px;
}
.livechat-mobile-container {
  border: none;
}

.ant-btn-primary {
  background-color: #04be8c;
  border-color: #04be8c;
}

.ant-btn-primary:hover,
.ant-btn-primary:active,
.ant-btn-primary:focus {
  background-color: #04816b; /* Darker shade for hover/focus */
  border-color: #05b696;
}

.livechat-mobile-title {
  margin-bottom: 48px;
}
.map {
  cursor: crosshair;
}
.menu-arrow-icon {
  color: #424242;
  width: 14px;
  height: 14px;
  margin-bottom: 2px;
}
