.left-border-container {
  position: absolute;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  opacity: 0;
}
.sticky-button {
  position: relative;
  right: 10px;
  top: 20px;
  color: #424242;
  width: 24px;
  height: 24px;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #e5e5e5;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
.sticky-button:hover,
.close-customization-container:hover .sticky-button {
  color: #fff;
  background: #3B41EC;
  border: none;
}

.close-customization-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  padding-left: 10px;
}
.close-customization-container:hover .left-border-container {
  opacity: 1;
}

.customization-drawer-container {
  height: 100%;
  padding: 24px;
  background-color: #fff;
  z-index: 1;
}

.customization-title-row {
  display: flex;
  align-items: center;
  height: 28px;
  margin-bottom: 24px;
}
.customization-title-text {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin-left: 8px;
}
.action-button-row {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: 20px;
}
.action-button {
  background-color: #f5f5f5;
}
