.code-menu {
  border: 1px solid #ccc; /* Light grey border */
  background-color: #fff; /* White background */
  color: #212121;
  border-radius: 8px; /* Rounded corners */
  padding: 12px; /* Some padding */
  font-size: 16px; /* Text size */
  width: 125px;
  height: 48px;
  font-weight: 600;
  cursor: pointer; /* Cursor pointer */
  display: flex; /* Use flexbox for internal alignment */
  align-items: center; /* Align items vertically */
  justify-content: space-between; /* Space between icon and text */
}

.menu:hover {
  background-color: #f5f5f5;
  color: #333;
  border-color: #ccc;
}

.menu.active {
  background-color: #f0f0f0; /* Slightly different background for active state */
}
.uikit-menu {
  width: 135px;
}
