.right-border-container {
  position: absolute;
  height: 100%;
  cursor: pointer;
  border-right: 2px solid #04be8c;
  z-index: 2;
  opacity: 0;
}
.sticky-button-layer-drawer {
  position: relative;
  right: 24px;
  top: 20px;
  color: #424242;
  width: 24px; 
  height: 24px;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #e5e5e5;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.sticky-button-layer-drawer:hover,
.close-layer-container:hover .sticky-button-layer-drawer {
  color: #fff;
  background: #3B41EC;
  border: none;
}
.close-layer-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 310px;
  cursor: pointer;
  padding-left: 10px;
}
.right-border-container:hover {
  opacity: 1;
}
.close-layer-container:hover .right-border-container {
  opacity: 1;
}
.layer-drawer-container {
  background: #ffffff;
  height: 100%;
  padding: 24px 16px;
}

.global-theme-text {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: #212121;
  padding-left: 14px;
}
.global-theme-row {
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-radius: 8px;
  cursor: pointer;
}
.customize-icon-container {
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  padding: 8px;
  cursor: pointer;
}
.customize-icon-container:hover {
  background-color: #e5e5e5;
}
.section-text {
  color: #9e9e9e;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 16px;
  padding-left: 8px;
}
.layer-arrow-button {
  width: 14px;
  height: 14px;
}
.section-container {
  padding: 8px 0px;
}
.section-divider {
  height: 1px;
  background: #e5e5e5;
  margin: 16px 0px;
}
.inactive-state:hover .global-theme-text {
  background: #f5f5f5;
}
