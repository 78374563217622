.dropdown {
  display: none; /* Hide by default */
  position: absolute;
  top: 100%; /* Position right below the button */
  left: 0;
  background-color: #fff; /* White background */
  min-width: 160%; /* Same width as the button */
  z-index: 100; /* Ensure it sits on top of other content */
  border-radius: 12px; /* Rounded corners */
  overflow: hidden; /* Hide overflow for rounded corners */
  padding: 16px;
  box-shadow: 2px 6px 16px 0px #00000029;
  z-index: 9999;
}

.dropdown.active {
  display: block; /* Show the dropdown */
  animation: fadeIn 0.2s ease-out; /* Fade in animation */
  margin-top: 8px; /* Corrected margin-top */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dropdown-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 12px 10px 12px;
  cursor: pointer;
}
.dropdown-item:hover {
  background-color: #f5f5f5;
  color: #333;
  border-color: #ccc;
  border-radius: 12px;
}

/* .dropdown-item.selected {
    background-color: #f5f5f5;

} */

.dropdown-item-text.bold {
  font-weight: 400; /* Bold text */
}

.dropdown-item-icon {
  margin-right: 16px;
}

.dropdown-item-text {
  font-weight: 400;
  font-size: 14px;
}

.dropdown-item-checkmark {
  margin-left: auto; /* Align checkmark to the right */
  width: 24px;
  height: 24px;
}
