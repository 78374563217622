.nav-container {
  flex-grow: 1;
}
.inspect-btn {
  margin-right: 8px;
  width: 140px;
  font-size: 16px;
}
.inspect-btn:hover {
  background-color: #dcdcdc;
  color: #333;
  border-color: #ccc;
}
.categories-title-column {
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  padding-left: 24px;
}
.categories-title-column p {
  margin-top: 4px;
  margin-bottom: 16px;
  font-size: 11px;
  color: var(--Text-Gray-5, #9e9e9e);
  font-weight: 400;
  line-height: 16px; /* 145.455% */
  letter-spacing: -0.1px;
}
.categories-title-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.categories-title-row {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
}
.drawer-title-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.button-icon {
  width: 24px;
  height: 24px;
  color: var(--Icon-Gray2, #424242);
  margin-right: 8px;
}
.reset-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  color: var(--Icon-Gray2, #424242);
}
.qr-icon {
  width: 24px;
  height: 24px;
  margin-right: 4px;
  color: var(--Background-White, #fff);
}
.nav-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  height: 80px;
  padding: 0px 24px;

}
.group-item{
  display: flex;
  gap: 8px;
}
.btn-row {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-right: 16px;
}

.btn-row > *:not(:last-child) {
  margin-right: 8px; /* This applies margin to all children except the last one */
}

.playground-title {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: var(--Text-Gray-1, #212121);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  margin-left: 24px;
}
.refresh-btn {
  width: 180px;
}
.modal-qr-container {
  text-align: center;
  border-top: 1px solid #ebecef;
}
.qr-code {
  width: 230px;
  border: 1px solid #ebecef;
  padding: 4px 16px;
  border-radius: 16px;
  margin: 24px;
}
.my-qr-modal .ant-modal-content {
  padding: 24px 0px;
}
.my-qr-modal .ant-modal-title {
  padding-bottom: 12px;
  padding-left: 24px;
}
.my-qr-modal .ant-modal-close {
  padding-top: 12px;
}
.qr-code-text-container {
  display: flex;
  justify-content: start;
  margin-left: 36px;
}
.play-icon {
  color: #01b8dd;
  margin-right: 6px;
  width: 16px;
  height: 16px;
}
.qr-code-text-row-1 {
  display: flex;
  align-items: center;
}
.bold-text {
  font-weight: bold; /* Makes the text bold */
}
.qr-code-text {
  text-align: left;
  margin: 0px;
  line-height: 1.3;
  margin-bottom: 8px;
}

.categories-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.categories-button-rows {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 1rem;  */
  margin-left: 1rem;
  height: 36px;
  gap: 8px;
}

.categories-title {
  margin-right: 0.5rem; /* Adjust as necessary */
  font-weight: 600; /* Adjust as necessary */
  font-size: 16px;
}

.category-btn {
  height: 36px;
  padding: 8px 12px 8px 10px !important;
  border-radius: 18px !important;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  color: var(--Neutral-Gray-2, #424242);
  text-align: center;

  /* Body 2/14 Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.2px;
}
.category-btn-hidden {
  display: none;
}

.category-btn:hover {
  background-color: #e6f9f4;
}
.category-btn-selected {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(209.43deg, #339af9 12.36%, #78fa58 89.4%) border-box;
  border-radius: inherit;
  border: 1.5px solid transparent;
}
.category-btn-selected:hover {
  background: linear-gradient(#e6f9f4, #e6f9f4) padding-box,
    linear-gradient(209.43deg, #339af9 12.36%, #78fa58 89.4%) border-box;
}

.category-btn-selected .category-button-icon {
  color: inherit !important;
}

.category-button-icon {
  margin-right: 4px;
  width: 20px;
  height: 20px;
}

.switch-row {
  display: flex;
  align-items: center;
  margin-left: 36px;
  gap: 8px;
}
.title-navbar-row{
  display: flex;
  align-items: center;
}
.play-uikit-btn{
background-color: #FFFFFF;
border-radius: 8;
border: 1px solid #E5E5E5;
height: 48px;
padding: 12px;
cursor: pointer;
}
.play-uikit-btn:hover{
  background-color:#E5E5E5;
}