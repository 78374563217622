
.content-icon{
  margin-top: 4px;
}
.menu-down-icon {
  color: #424242;
  width: 14px;
  height: 14px;
  margin-bottom: 2px;
}
.menu:hover {
    background-color: #F5F5F5;
  color: #333;
  border-color: #ccc;
}

.menu.active {
    background-color: #f0f0f0; /* Slightly different background for active state */
}
/* button:hover {
  color: #fff;
  background-color: #0069d9;
} */

.content-menu{
  width: 164px;


}