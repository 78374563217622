.code-drawer-modal {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.code-drawer-container {
  width: 77%;
  height: 100%;
  padding: 24px;
  background-color: #fff;
  z-index: 1;
  float: right;
  overflow-y: auto;

}

.code-drawer-title {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-family: Inter;
  font-weight: 600;
  align-items: center;
}
.code-drawer-description {
  margin-top: 10px;
  margin-bottom: 24px;
  color: var(--Neutral-Gray-2, #424242);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.json-code-snippet {
  padding: 16px 16px 16px 16px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  height: 550px;
}
.scrollable-code-block {
  max-height: 550px; /* Adjust this value to your desired height */
  overflow-y: auto;
  background-color: #282A36; /* Ensuring it matches the Dracula theme background */
  padding: 16px;
  border-radius: 8px;
}