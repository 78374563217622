.color-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: "center";
  cursor: "pointer";
  border-radius: 0px 4px 4px 0px;
}
.color-input-container {
  position: relative;
  display: flex;
  align-items: center;

}
.color-code-text {
  width: 180px;
  padding: 9px;
  border: 1px solid #e5e5e5;
}
.color-input-header {
  border: 1px solid #e5e5e5;
  background: #f5f5f5;
  padding: 9px;

  color: #9e9e9e;
  border-radius: 4px 0px 0px 4px;
  text-align: center;
}
.color-input-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  padding: 0;


}
