/* Common button styles */
.primary-btn,
.secondary-btn {
  padding: 12px 16px 12px 12px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Primary button specific styles */
.primary-btn {
  border: 1px solid #e5e5e5;
  background: var(--Primary-Active, #04be8c);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: var(--Background-White, #fff);
}

/* Secondary button specific styles */
.secondary-btn {
  background: #F5F5F5;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  justify-content: center;
  color: var(--Text-Gray1, #212121);
}

/* Icon styles */
.primary-icon,
.secondary-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

/* Specific icon color */
.primary-icon {
  color: var(--Background-White, #fff);
}

.secondary-icon {
  color: var(--Icon-Gray2, #424242);
}

/* Hover and active states for primary button */
.primary-btn:hover {
  background-color: #1ed29c; /* Brighter background on hover */
  border-color: #1ed29c; /* Change border color on hover */
  color: #fff; /* Lighter text color on hover */
}

.primary-btn:active {
  background-color: #027d5f; /* Change background color when clicked */
  border-color: #026e52; /* Change border color when clicked */
}

/* Hover and active states for secondary button */
.secondary-btn:hover {
  background-color: #dcdcdc;
  color: #333;
  border-color: #ccc;
}

/* .secondary-btn:active {
  background-color: #ddd;
  color: #222;
  border-color: #aaa;
} */
