.form-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 4px;
}
.form-subtitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0px;
  margin-bottom: 24px;
}
.select-module-row {
  display: flex;
  gap: 16px;
}
.module-item-box {
  border-radius: 20px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0px 0px 8px 0px rgba(40, 41, 61, 0.08);
  width: 50%;
  height: 30%;
  padding: 15px;
  cursor: pointer;
}
.module-box-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 12px;
}

.start-btn {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-radio-wrapper .ant-radio-input:checked + .ant-radio-inner {
  border-color: #04be8c;
  background-color: #04be8c;
}
.hide{
    display: none;
}