.App {
  display: flex;
  height: 100%;
  font-family: 'Inter',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
.ant-form-item-label {
  color: var(--Neutral-Gray-1, #212121);
  font-family: 'Inter',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
}
.custom-input {
  width: 70vh ;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Gray-7, #e0e0e0);
  background: var(--Neutral-White, #fff);
  padding: 12px;
}
button {
  width: 100%;
  padding: 10px;
  background-color: #007aff;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #005ecb;
}

button:disabled {
  background-color: #007aff;
  opacity: 0.5;
  cursor: default;
}

button:disabled:hover {
  background-color: #007aff;
}
.welcome-img {
  width: 400px;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #007aff;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.modal-content {
  background: white;
  padding: 40px;
  border-radius: 24px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 70vh;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loading-content {
  display: flex;
  justify-content: center;
}
.loading-text {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-title {
  color: var(--Neutral-Gray-1, #212121);
  /* Headline/H3 24 Bold */
  font-family: 'Inter',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  letter-spacing: -0.4px;
  margin: 0px;
}
.modal-subtitle {
  color: var(--Neutral-Gray-3, #616161);
  font-family: 'Inter',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  letter-spacing: -0.2px;
  margin-bottom: 36px;
}
.save-icon {
  width: 24px;
  height: 24px;
  color: var(--Background-White, #fff);
  margin-right: 8px;
}
.btn-wrap {
  display: flex;
  justify-content: center;
}
.save-btn {
  text-align: center;
  padding: 0px 20px;
}
.modal-btn-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* for 16:9 aspect ratio, for example */
  height: 0;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.video-wrap{
  object-fit: contain;
  margin-right:8px;
}

.none{
  display: none;
}