.zoom-button-container {
height: 48px;
border-radius: 8px;
background: #FFF;
border: 1px solid #E5E5E5;
display: flex;
align-items: center;
}
.zoom-icon{
  color: #424242;
  padding: 12px;
  cursor: pointer;
}
.zoom-icon:hover{
  background-color: #E5E5E5;
}
.icon-divider{
  height: 24px;
  width: 1px;
  background: #E5E5E5;
}