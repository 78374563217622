.layer-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 6px;
  border-radius: 0px 8px 8px 0px;
  cursor: pointer;
  color: #616161;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin: 4px 0px;
  z-index: 1;
}

.layer-title-row {
  display: flex;
  align-items: center;
}

.layer-name {
  margin: 0;
  margin-left: 10px;
}

.icon {
  display: inline-block;
  transition: transform 0.15s ease-out;
  padding: 2px;
}
.arrow-icon-container {
  z-index: 2;
}
.icon.rotate:hover {
  background-color: #3769EC;
  opacity: 0.1;
  border-radius: 25px;
}
.icon.rotate.inactive-state:hover {
  background-color: #E5E5E5;
  border-radius: 25px;
}
.icon.rotate {
  transform: rotate(90deg); /* Rotates the icon 90 degrees to the right */
}

.inactive-state {
  background: #ffffff;
}

.inactive-state:hover, 
.inactive-state:hover .icon {
  background: #f5f5f5;
}
.active-state {
  color: #3B41EC;
  background-color: #3B41EC1A;
}
.active-state-text {
  color: #3B41EC;
}
.component-container {
  overflow: hidden;
  max-height: 0;
  transform: translateY(-20px);
  opacity: 0;
  transition: max-height 0.15s ease-out, transform 0.2s ease-out,
    opacity 0.2s ease-out;
}

.component-container.expand {
  max-height: 1000px; /* Adjust based on your content height */
  transform: translateY(0);
  opacity: 1;
}

.component-layer {
  padding-left: 24px;
}
.element-layer {
  padding-left: 40px;
}
.component-layer {
  padding-left: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
}
