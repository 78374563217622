.chat-container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-modal-content {
  background: white;
  margin: 20px;
  border-radius: 24px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  height: 700px;
  width:395px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loading-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* height: 7px; */
}
.chat-main-container {
  height: calc(100% - 80px);
}
.ant-drawer-right>.ant-drawer-content-wrapper {
    box-shadow: none !important;
    border-left: 1px solid #EEEEEE 
}
.ant-drawer {
  z-index: 99 !important;
}
.ant-drawer .ant-drawer-body {
  padding-top: 8px;
  padding: 8px 16px 16px 16px;
}
.ant-drawer-header{
border-bottom: none !important;
}
.chat-customization-btn-container{
  position: absolute; /* Position the button absolutely */
  top: 20px; /* Adjust top position as needed */
  right:24px; /* Adjust right position as needed */
  width:200px;
  z-index: 1;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #60617029, 0px 0px 1px 0px #28293D0A;
}
.customization-btn{
border: 1px solid #ccc; /* Light grey border */
    background: #fff; /* White background */
    color: #212121;
    border-radius: 8px; /* Rounded corners */
    padding: 10px 8px; /* Some padding */
    font-size: 16px; /* Text size */
    width: 210px;
    height: 48px;
    cursor: pointer; /* Cursor pointer */
    display: flex; /* Use flexbox for internal alignment */
    align-items: center; /* Align items vertically */
    transition: background-color 0.3s ease;
}
.customization-btn:hover {
  background-color: #F5F5F5;
  color: #333;
  border-color: #ccc;
}

.customization-btn > div {
    display: flex;
    justify-content: center; /* Center children */
    align-items: center; /* Align items vertically */
    flex: 1; /* Flex grow to fill available space */
}
.customization-btn > div > * {
    margin: 0 0px; /* Provide equal margin to all children */
}
.customization-button-left-icon {
  width:24px;
  height:24px;
  margin-right:8px;
  margin-left: 12px;
}
.customization-button-right-icon {
  width:24px;
  height:24px;
  margin-left:8px;
  margin-right: 16px;
}
.description {
  text-align: center;
  color: var(--Neutral-Gray-1, #212121);
  text-align: center;
  /* Headline / Title 20 Semibold */
  font-family: 'Inter',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
  letter-spacing: -0.4px;
}
.subtitle {
  color: var(--Neutral-Gray-3, #616161);
  text-align: center;
  font-family: 'Inter',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.2px;
}
.chat-iframe {
  border: none;
  box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.2),
    0px 0px 2px 0px rgba(40, 41, 61, 0.1);
  border-radius: 20px;
  margin: 20px;
  background-color: #fff;
  padding-bottom: 5px;
}
